<template>
    <v-skeleton-loader v-if="loading" />
    <v-container v-else>
        <v-card>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="unit.aemoId" label="DUID" required outlined />
                            <v-autocomplete auto-select-first v-model="unit.stationId" :items="stations" item-value="id" item-text="name" label="Station" required filled />
                            <v-autocomplete auto-select-first v-model="participantTypeId" :items="participantTypes" item-value="id" item-text="name" label="Participant Type" required filled />
                            <v-autocomplete auto-select-first v-model="aemoClassId" :items="aemoClasses" item-value="id" item-text="name" label="Aemo Class" required filled />

                            <v-autocomplete
                                auto-select-first
                                v-model="generationTechnologyId"
                                :items="generationTechnologies"
                                item-value="id"
                                item-text="name"
                                label="Generation Technology"
                                required
                                filled
                            />
                            <v-autocomplete auto-select-first v-model="fuelTypeId" :items="fuelTypes" item-value="id" item-text="name" label="Fuel Type" required filled />
                            <v-autocomplete auto-select-first v-model="oemId" :items="turbineSuppliers" item-value="id" item-text="name" label="Turbine Supplier" required filled />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="unit.connectionPointId" label="Connection Point" required outlined />
                            <v-text-field v-model="unit.physUnitNo" label="Physical Unit No" outlined />
                            <v-text-field v-model="unit.unitSize" label="Unit Size" type="number" outlined />
                            <v-text-field v-model="unit.registeredCapacity" label="Registered Capacity" type="number" outlined />
                            <v-checkbox v-model="unit.deregistered" label="Deregistered" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn min-width="100" :to="{ name: 'unit-list' }">Back</v-btn>
                <v-btn min-width="100" class="primary" @click="save" :loading="saving">Save</v-btn>
                <v-alert v-if="alert" v-model="alert.visible" class="ma-3" :type="alert.type" transition="scale-transition" shaped text dense>
                    {{ alert.message }}
                </v-alert>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Unit',

    data() {
        return {
            loading: true,
            saving: false,
            valid: true,
            unit: null,
            alert: null,
            stations: [],
            participantTypes: [],
            aemoClasses: [],
            generationTechnologies: [],
            fuelTypes: [],
            turbineSuppliers: []
        };
    },

    async created() {
        await this.load();
    },

    computed: {
        id: function () {
            return this.$route.params.id;
        },
        participantTypeId: {
            get: function () {
                return this.unit.participantType.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.unit.participantType = this.participantTypes.find(i => i.id === value);
                }
            }
        },
        aemoClassId: {
            get: function () {
                return this.unit.aemoClass.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.unit.aemoClass = this.aemoClasses.find(i => i.id === value);
                }
            }
        },
        generationTechnologyId: {
            get: function () {
                return this.unit.generationTechnology.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.unit.generationTechnology = this.generationTechnologies.find(i => i.id === value);
                }
            }
        },
        fuelTypeId: {
            get: function () {
                return this.unit.fuelType.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.unit.fuelType = this.fuelTypes.find(i => i.id === value);
                }
            }
        },
        oemId: {
            get: function () {
                return this.unit.oem.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.unit.oem = this.turbineSuppliers.find(i => i.id === value);
                }
            }
        }
    },

    methods: {
        async save() {
            try {
                this.saving = true;
                this.alert = null;
                const response = await axios.put(`/api/units/${this.id}`, this.unit);
                this.alert = { message: 'Saved', type: response.status >= 200 && response.status < 300 ? 'success' : 'error' };
                this.saving = false;
            } catch {
                this.alert = { message: 'Error', type: 'error' };
                this.saving = false;
            }
        },
        async load() {
            this.loading = true;
            this.stations = (await axios.get('/api/stations')).data;
            this.participantTypes = (await axios.get('/api/participant-types')).data;
            this.aemoClasses = (await axios.get('/api/aemo-classes')).data;
            this.generationTechnologies = (await axios.get('/api/generation-technologies')).data;
            this.fuelTypes = (await axios.get('/api/fuel-types')).data;
            this.turbineSuppliers = (await axios.get('/api/turbine-suppliers')).data;
            this.unit = (await axios.get(`/api/units/${this.id}`)).data;
            this.loading = false;
        }
    },

    watch: {
        async $route() {
            await this.load();
        }
    }
};
</script>
